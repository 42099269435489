import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  getCurrentWalletNFTs,
  mintNFT,
} from "./util/interact.js";

const Minter = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const [amount, setAmount] = useState("");

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();

    setWallet(address);
    setStatus(status);

    addWalletListener();
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {

    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const scanForNFTs = async () => {

    const listNFTsResponse = await getCurrentWalletNFTs();
    console.log(listNFTsResponse);
  };

  const onMintPressed = async () => {
    const { success, status } = await mintNFT(amount);
    setStatus(status);
    if (success) {
      setAmount("");
    }
  };

  return (
    <div className="Minter">
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>

      <br></br>
      <img src="https://gateway.pinata.cloud/ipfs/QmZb3u8DbZFhwY9BsuyZ9wb5M8ovamgYUnL7Bz5erREyKw" className="logo"></img>
      <br></br>
      <h1 id="title">Idiot Friends NFT Mint</h1>
        <p><strong>What are Idiot Friends?</strong> <br />A collection of <strong>4345</strong> NFTs on the Polygon chain generated using 150+ hand drawn layers by <a href="https://twitter.com/sup">Andy Feliciotti</a>.</p>
        <p><strong>How much does it cost to mint an Idiot Friend?</strong><br /> Each Idiot Friend is 0.08 MATIC (roughly 17 cents).</p>
        <p><strong>Is there a roadmap?</strong><br /> None this project was to learn NFT creation and to have fun!</p>
      <p>
        Simply press "Mint." to recieve your very own Idiot Friend!
      </p>
      <form>
      <strong>How many would you like?</strong>
      <select onChange={(event) => setAmount(event.target.value)}>
        <option>0</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
      </select>
      </form>
      <button id="mintButton" onClick={onMintPressed}>
        Mint Idiot Friend
      </button>
      <p id="status" style={{ color: "red" }}>
        {status}
      </p>

      <img src="https://bafybeid42lwnh4xpvktcz3nxlhalqdy4nqzwrdzfi7272ggl7in2nnafti.ipfs.dweb.link/" className="preview"></img>
      <br />
      <a target="_blank" href="https://opensea.io/collection/idiot-friends/">Opensea</a>
      <br></br>
      <br></br>
      Contact Address: 0x8a5889d95c218121f8c2db9bce7a92331b5ee78b
    </div>
  );
};

export default Minter;
